import form, {
  rolesForm,
  signatureAttachmentsForm,
} from "pages/settings/user/new/schemas/form";

const {
  formField: {
    displayName,
    designation,
    roles,
    emailSignOff,
    signatureAttachments,
  },
} = form;

const initialValues = {
  [displayName.name]: "",
  [designation.name]: "",
  [roles.name]: [],
  [emailSignOff]: "",
  [signatureAttachments.name]: [],
};

const {
  formField: { roleName },
} = rolesForm;

const rolesInitialValues = {
  [roleName.name]: "",
};

const {
  formField: { signatureAttachmentFile },
} = signatureAttachmentsForm;

const signatureAttachmentsInitialValues = {
  [signatureAttachmentFile.name]: "",
};

export {
  initialValues as default,
  rolesInitialValues,
  signatureAttachmentsInitialValues,
};
