import { useState, useCallback, useEffect } from "react";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";

import { useCollection } from "hooks/useCollection";

const rolesQueries = {
  whereQueries: [
    {
      field: "deletedAt",
      condition: "==",
      value: null,
    },
    {
      field: "roleName",
      condition: "!=",
      value: "ROOT",
    },
  ],
};

function Roles({
  mainForm,
  mainFormField,
  modeDisabled,
  dispatchMainError,
  selectedRole,
  setSelectedRole,
}) {
  const { values, setFieldValue } = mainForm;
  const { roleName, permissions } = mainFormField;

  const [roleOptions, setRoleOptions] = useState([]);

  const { collectionData: rolesData } = useCollection("roles", rolesQueries);

  const resetRole = useCallback(() => {
    setSelectedRole(null);
    setFieldValue(permissions.name, []);
  }, [permissions.name, setFieldValue, setSelectedRole]);

  useEffect(() => {
    try {
      const roles = rolesData;
      setRoleOptions(roles);
      if (!roles.includes(selectedRole)) {
        resetRole();
      }
    } catch (err) {
      dispatchMainError(err);
    }
  }, [dispatchMainError, resetRole, rolesData, selectedRole]);

  useEffect(() => {
    try {
      if (!values.roleName) {
        setSelectedRole(null);
      }
    } catch (err) {
      dispatchMainError(err);
    }
  }, [dispatchMainError, setSelectedRole, values.roleName]);

  return (
    <Card id="roles">
      <MDBox m={3}>
        <MDBox mb={3}>
          <MDTypography variant="h5">Roles</MDTypography>
        </MDBox>

        <MDBox>
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 2 }}
            columnSpacing={{ xs: 1, sm: 3 }}
          >
            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                Roles
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                size="small"
                value={selectedRole}
                getOptionLabel={(option) => option.data.roleName}
                onChange={(e, role) => {
                  setSelectedRole(role);
                  // update role info value
                  setFieldValue(roleName.name, role.data.roleName);
                  setFieldValue(permissions.name, role.data.permissions);
                }}
                options={roleOptions}
                disabled={modeDisabled}
                renderInput={(params) => (
                  <TextField {...params} label="Select Role" />
                )}
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default Roles;
