const form = {
  formId: "customer-form",
  formField: {
    customerName: {
      name: "customerName",
      label: "Organization / Individual Name",
      type: "text",
      errorMsg: "Organization / Individual Name is required.",
      invalidMsg: "Organization / Individual Name is invalid.",
    },
    customerUEN: {
      name: "customerUEN",
      label: "UEN",
      type: "text",
      errorMsg: "UEN is required.",
      invalidMsg: "UEN is invalid.",
    },
    customerType: {
      name: "customerType",
      label: "Customer Type",
      type: "text",
      errorMsg: "Customer Type is required.",
    },
    customerStatus: {
      name: "customerStatus",
      label: "Customer Status",
      type: "text",
      errorMsg: "Customer Status is required.",
    },
    customerAddress: {
      name: "customerAddress",
      label: "Address",
      type: "text",
      errorMsg: "Address is required.",
      invalidMsg: "Address is invalid.",
    },
    customerBuildingName: {
      name: "customerBuildingName",
      label: "Building Name",
      type: "text",
      errorMsg: "Building Name is required.",
      invalidMsg: "Building Name is invalid.",
    },
    customerPostalCode: {
      name: "customerPostalCode",
      label: "Postal Code",
      type: "text",
      errorMsg: "Postal Code is required.",
      invalidMsg: "Postal Code is invalid.",
    },
    customerLandlineNumber: {
      name: "customerLandlineNumber",
      label: "Office / Home Contact Number",
      type: "text",
      errorMsg: "At least one contact number is required.",
      invalidMsg: "Office / Home Contact Number is invalid.",
    },
    customerMobileNumber: {
      name: "customerMobileNumber",
      label: "Mobile Contact Number",
      type: "text",
      errorMsg: "At least one contact number is required.",
      invalidMsg: "Mobile Contact Number is invalid.",
    },
    customerEmail: {
      name: "customerEmail",
      label: "Email",
      type: "text",
      errorMsg: "Email is required.",
      invalidMsg: "Email is invalid.",
    },
    customerWebsite: {
      name: "customerWebsite",
      label: "Website",
      type: "text",
      invalidMsg: "Website is invalid.",
    },
    customerPreferredContact: {
      name: "customerPreferredContact",
      label: "Customer Preferred Contact",
      type: "text",
      errorMsg: "Customer Preferred Contact is required.",
    },
    contactPersons: {
      name: "contactPersons",
      label: "Customer Contact Persons",
      type: "box",
      min1Msg: "Must have at least 1 Contact Person",
      max100Msg: "Must have at most 100 Contact Persons",
    },
    deliverySites: {
      name: "deliverySites",
      label: "Customer Delivery Sites",
      type: "box",
      min1Msg: "Must have at least 1 Delivery Site",
      max100Msg: "Must have at most 100 Delivery Sites",
    },
    billingContacts: {
      name: "billingContacts",
      label: "Customer Billing Contacts",
      type: "box",
      min1Msg: "Must have at least 1 Billing Contact",
      max100Msg: "Must have at most 100 Billing Contacts",
    },
  },
};

const contactPersonForm = {
  formId: "contact-person-form",
  formField: {
    contactPersonName: {
      name: "contactPersonName",
      label: "Contact Person Name",
      type: "text",
      errorMsg: "Contact Person Name is required.",
      invalidMsg: "Contact Person Name is invalid.",
    },
    contactPersonEmail: {
      name: "contactPersonEmail",
      label: "Email",
      type: "text",
      errorMsg: "Email is required.",
      invalidMsg: "Email is invalid.",
    },
    contactPersonLandlineNumber: {
      name: "contactPersonLandlineNumber",
      label: "Office / Home Contact Number",
      type: "text",
      errorMsg: "At least one contact number is required.",
      invalidMsg: "Office / Home Contact Number is invalid.",
    },
    contactPersonMobileNumber: {
      name: "contactPersonMobileNumber",
      label: "Mobile Contact Number",
      type: "text",
      errorMsg: "At least one contact number is required.",
      invalidMsg: "Mobile Contact Number is invalid.",
    },
  },
};

const deliverySiteForm = {
  formId: "delivery-sites-form",
  formField: {
    deliverySiteName: {
      name: "deliverySiteName",
      label: "Delivery Site Name",
      type: "text",
      errorMsg: "Delivery Site Name is required.",
      invalidMsg: "Delivery Site Name is invalid.",
    },
    siteSupervisorName: {
      name: "siteSupervisorName",
      label: "Site Supervisor Name",
      type: "text",
      errorMsg: "Site Supervisor Name is required.",
      invalidMsg: "Site Supervisor Name is invalid.",
    },
    deliverySiteAddress: {
      name: "deliverySiteAddress",
      label: "Delivery Site Address",
      type: "text",
      errorMsg: "Delivery Site Address is required.",
      invalidMsg: "Delivery Site Address is invalid.",
    },
    deliverySiteBlkBuildingName: {
      name: "deliverySiteBlkBuildingName",
      label: "Blk / Building Name",
      type: "text",
      errorMsg: "Blk / Building Name is required.",
      invalidMsg: "Blk / Building Name is invalid.",
    },
    deliverySitePostalCode: {
      name: "deliverySitePostalCode",
      label: "Postal Code",
      type: "text",
      errorMsg: "Postal Code is required.",
      invalidMsg: "Postal Code is invalid.",
    },
    deliverySiteEmail: {
      name: "deliverySiteEmail",
      label: "Email",
      type: "text",
      errorMsg: "Email is required.",
      invalidMsg: "Email is invalid.",
    },
    deliverySiteLandlineNumber: {
      name: "deliverySiteLandlineNumber",
      label: "Office / Home Contact Number",
      type: "text",
      errorMsg: "At least one contact number is required.",
      invalidMsg: "Office / Home Contact Number is invalid.",
    },
    deliverySiteMobileNumber: {
      name: "deliverySiteMobileNumber",
      label: "Mobile Contact Number",
      type: "text",
      errorMsg: "At least one contact number is required.",
      invalidMsg: "Mobile Contact Number is invalid.",
    },
  },
};

const billingContactForm = {
  formId: "billing-contacts-form",
  formField: {
    billingContactName: {
      name: "billingContactName",
      label: "Billing Contact Name",
      type: "text",
      errorMsg: "Billing Contact Name is required.",
      invalidMsg: "Billing Contact Name is invalid.",
    },
    billingContactAddress: {
      name: "billingContactAddress",
      label: "Billing Contact Address",
      type: "text",
      errorMsg: "Billing Contact Address is required.",
      invalidMsg: "Billing Contact Address is invalid.",
    },
    billingContactBlkBuildingName: {
      name: "billingContactBlkBuildingName",
      label: "Blk / Building Name",
      type: "text",
      errorMsg: "Blk / Building Name is required.",
      invalidMsg: "Blk / Building Name is invalid.",
    },
    billingContactPostalCode: {
      name: "billingContactPostalCode",
      label: "Postal Code",
      type: "text",
      errorMsg: "Postal Code is required.",
      invalidMsg: "Postal Code is invalid.",
    },
    billingContactEmail: {
      name: "billingContactEmail",
      label: "Email",
      type: "text",
      errorMsg: "Email is required.",
      invalidMsg: "Email is invalid.",
    },
    billingContactLandlineNumber: {
      name: "billingContactLandlineNumber",
      label: "Office / Home Contact Number",
      type: "text",
      errorMsg: "At least one contact number is required.",
      invalidMsg: "Office / Home Contact Number is invalid.",
    },
    billingContactMobileNumber: {
      name: "billingContactMobileNumber",
      label: "Mobile Contact Number",
      type: "text",
      errorMsg: "At least one contact number is required.",
      invalidMsg: "Mobile Contact Number is invalid.",
    },
  },
};

export {
  form as default,
  contactPersonForm,
  deliverySiteForm,
  billingContactForm,
};
