import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";

import { AuthContextProvider } from "context/AuthContext";
import { MuiContextProvider } from "context/MuiContext";

ReactDOM.render(
  <BrowserRouter>
    <AuthContextProvider>
      <MuiContextProvider>
        <App />
      </MuiContextProvider>
    </AuthContextProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
