const form = {
  formId: "account-form",
  formField: {
    displayName: {
      name: "displayName",
      label: "Display Name",
      type: "text",
      errorMsg: "Display Nameis required.",
      invalidMsg: "Display Name is invalid.",
    },
    designation: {
      name: "designation",
      label: "Designation",
      type: "text",
      errorMsg: "Designation is required.",
      invalidMsg: "Designation is invalid.",
    },
    email: {
      name: "email",
      label: "Email",
      type: "text",
      errorMsg: "Email is required.",
      invalidMsg: "Email is invalid.",
    },
    roles: {
      name: "roles",
      label: "Roles",
      type: "box",
      min1Msg: "Must have at least 1 Role.",
      max50Msg: "Must have at most 50 Roles.",
    },
    emailSignOff: {
      name: "emailSignOff",
      label: "Email Sign Off",
      type: "text",
      errorMsg: "Email Sign Off is required.",
      invalidMsg: "Email Sign Off is invalid.",
    },
    signatureAttachments: {
      name: "signatureAttachments",
      label: "Signature Attachment",
      type: "box",
      min1Msg: "Must have at least 1 Attachment.",
      max20Msg: "Must have at most 20 Attachments.",
    },
  },
};

const rolesForm = {
  formId: "roles-form",
  formField: {
    roleName: {
      name: "roleName",
      label: "Role",
      type: "text",
      errorMsg: "Role is required.",
      invalidMsg: "Role is invalid.",
    },
  },
};

const signatureAttachmentsForm = {
  formId: "signature-attachments-form",
  formField: {
    signatureAttachmentFile: {
      name: "signatureAttachmentsFile",
      label: "Attachment File",
      type: "file",
      errorMsg: "Attachment File is required.",
      invalidMsg: "File size must be less than 5 MB.",
    },
    attachmentPath: {
      name: "attachmentPath",
      label: "Attachment Path",
      type: "text",
    },
    attachmentName: {
      name: "attachmentName",
      label: "Attachment Path",
      type: "text",
    },
    attachmentURL: {
      name: "attachmentURL",
      label: "Attachment Path",
      type: "text",
    },
  },
};

export { form as default, rolesForm, signatureAttachmentsForm };
