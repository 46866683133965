import { useLocation, Navigate } from "react-router-dom";

import { useAuthContext } from "context/AuthContext";

import PropTypes from "prop-types";

function RequireAuth({ children }) {
  let { user } = useAuthContext();
  let location = useLocation();

  console.log("!user: " + !user);
  if (!user) {
    console.log("User is not signed in");
    // user is not signed in
    // redirect user to /authentication/signin page
    // save the current location user were trying to access
    // thus allows to send user to the intended location after sign in
    // rather than sending them to the home page
    return (
      <Navigate
        to="/authentication/signup"
        state={{ from: location }}
        replace
      />
    );
  } else if (
    user &&
    !user.emailVerified &&
    location.pathname !== "/verifyemail"
  ) {
    // user is signed in but email is not verified
    // redirect user to /authentication/verifyemail page
    return (
      <Navigate
        to="/authentication/verifyemail"
        state={{ from: location }}
        replace
      />
    );
  }

  return children;
}

RequireAuth.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RequireAuth;
