const Role = Object.freeze({
  SUPERADMIN: "SUPERADMIN",
  ADMIN: "ADMIN",
  STUDENT: "STUDENT",
  MEMBER: "MEMBER",
});

const Permission = Object.freeze({
  // permission of dashboard (as shown on side nav bar)
  VIEW_SUPERADMIN_DASHBOARD: "VIEW_SUPERADMIN_DASHBOARD",
  VIEW_ADMIN_DASHBOARD: "VIEW_ADMIN_DASHBOARD",
  VIEW_MEMBER_DASHBOARD: "VIEW_MEMBER_DASHBOARD",

  // permission for data (also use for firestore rules)
  PERFORM_SUPERADMIN_ACTION: "PERFORM_SUPERADMIN_ACTION",
  PERFORM_ADMIN_ACTION: "PERFORM_ADMIN_ACTION",

  READ_ALL_ROLES: "READ_ALL_ROLES",
  CREATE_ROLE: "CREATE_ROLE",
  UPDATE_ROLE: "UPDATE_ROLE",
  READ_ROLE: "READ_ROLE",
  DELETE_ROLE: "DELETE_ROLE",
  // PURGE_ROLE: "PURGE_ROLE",

  READ_ALL_USERS: "READ_ALL_USERS",
  CREATE_USER: "CREATE_USER",
  UPDATE_USER: "UPDATE_USER",
  READ_USER: "READ_USER",
  DELETE_USER: "DELETE_USER",
  // PURGE_USER: "PURGE_USER",

  VIEW_CSESURVEY_ACTION: "VIEW_CSESURVEY_ACTION",
  READ_ALL_CSESURVEYS: "READ_ALL_CSESURVEYS",
  CREATE_CSESURVEY: "CREATE_CSESURVEY",
  UPDATE_CSESURVEY: "UPDATE_CSESURVEY",
  READ_CSESURVEY: "READ_CSESURVEY",
  PRINT_CSESURVEY: "PRINT_CSESURVEY",
  DELETE_CSESURVEY: "DELETE_CSESURVEY",
  PURGE_CSESURVEY: "PURGE_CSESURVEY",
  EXPORT_CSESURVEYS: "EXPORT_CSESURVEYS",
});

export { Role, Permission };
