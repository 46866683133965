// Don't handle exception in this React Hook to allow exception bubbling
// Handle exception at upwards level:
//   send error log back to server
//   show generic error message on snackbar to inform user to report to admin

import { useCallback } from "react";
import {
  createDocument,
  retrieveDocument,
  retrieveDocuments,
  updateDocument,
  deleteDocument,
  purgeDocument,
  serverTimestamp,
  convertToTimestamp,
} from "services/firestore";

export const useDocument = () => {
  const createDoc = useCallback(
    async (collectionPath, document, userId = null, documentId = null) => {
      const createdDocSnap = await createDocument(
        collectionPath,
        {
          ...document,
          createdAt: serverTimestamp(),
          createdBy: userId,
          modifiedAt: null,
          modifiedBy: null,
          deletedAt: null,
          deletedBy: null,
        },
        documentId
      );
      const createdDoc = {
        id: createdDocSnap.id,
        data: createdDocSnap.data(),
      };

      return createdDoc;
    },
    []
  );

  const retrieveDoc = useCallback(async (collectionPath, documentId) => {
    const retrievedDocSnap = await retrieveDocument(collectionPath, documentId);
    const retrievedDoc = {
      id: retrievedDocSnap.id,
      data: retrievedDocSnap.data(),
    };

    return retrievedDoc;
  }, []);

  const retrieveDocs = useCallback(async (collectionPath, collectionQuery) => {
    const retrievedDocsSnap = await retrieveDocuments(
      collectionPath,
      collectionQuery
    );

    let retrievedDocs = [];
    retrievedDocsSnap.forEach((doc) => {
      retrievedDocs.push({ id: doc.id, data: doc.data() });
    });

    return retrievedDocs;
  }, []);

  const updateDoc = useCallback(
    async (collectionPath, documentId, document) => {
      const updatedDocSnap = await updateDocument(
        collectionPath,
        documentId,
        document
      );
      const updatedDoc = {
        id: updatedDocSnap.id,
        data: updatedDocSnap.data(),
      };

      return updatedDoc;
    },
    []
  );

  const deleteDoc = useCallback(
    async (collectionPath, documentId, document) => {
      const deletedDocSnap = await deleteDocument(
        collectionPath,
        documentId,
        document
      );
      const deletedDoc = {
        id: deletedDocSnap.id,
        data: deletedDocSnap.data(),
      };

      return deletedDoc;
    },
    []
  );

  const purgeDoc = useCallback(async (collectionPath, documentId) => {
    await purgeDocument(collectionPath, documentId);
    return true;
  }, []);

  return {
    createDoc,
    retrieveDoc,
    retrieveDocs,
    updateDoc,
    deleteDoc,
    purgeDoc,
    serverTimestamp,
    convertToTimestamp,
  };
};
