import PropTypes from "prop-types";

import { useState, useRef } from "react";

import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { TransitionGroup } from "react-transition-group";
import Collapse from "@mui/material/Collapse";
import TextField from "@mui/material/TextField";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";

import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";
import MDTypography from "components/atoms/MDTypography";

import { Formik, ErrorMessage, FieldArray } from "formik";

import { useFile } from "hooks/useFile";

import { v4 as uuidv4 } from "uuid";

function FormAttachments({
  form,
  field,
  max,
  disabled,
  dispatchError,
  itemFormId,
  itemInitialValues,
  itemValidation,
  itemFileField,
  itemKeyField,
  itemDisplayFields,
  removable,
  showTitle,
}) {
  const { values } = form;
  const fieldArrayValue = values[field.name];

  const [selectedIndex, setSelectedIndex] = useState(null);
  const [choosenFile, setChoosenFile] = useState(null);
  const inputFile = useRef();
  const [itemFormValues, setItemFormValues] = useState(itemInitialValues);

  const { uploadFile, deleteFile /*,dispatchError*/ } = useFile();

  const handleListItemClick = (index) => {
    try {
      setSelectedIndex(index);
      disabled && setItemFormValues(fieldArrayValue[index]);
    } catch (err) {
      dispatchError(err);
    }
  };

  function renderItem(item, index, remove) {
    const itemDisplay = itemDisplayFields
      .map((displayField) => {
        return item[displayField.name];
      })
      .join(" - ");

    return (
      <>
        <ListItem
          secondaryAction={
            !disabled &&
            removable && (
              <>
                <IconButton
                  size="small"
                  color="error"
                  disabled={index !== selectedIndex}
                  onClick={() => removeItem(index, remove)}
                >
                  <DeleteIcon />
                </IconButton>
              </>
            )
          }
          disablePadding
        >
          <ListItemButton
            selected={selectedIndex === index}
            onClick={() => handleListItemClick(index)}
          >
            <IconButton
              edge="start"
              size="small"
              color="info"
              disabled={index !== selectedIndex}
              href={item[itemKeyField.name]}
              target="_blank"
              rel="noopener"
            >
              <VisibilityIcon />
            </IconButton>
            <ListItemText sx={{ ml: 1 }} primary={itemDisplay} />
          </ListItemButton>
        </ListItem>
      </>
    );
  }

  const removeItem = async (index, remove) => {
    try {
      await deleteFile(fieldArrayValue[index].attachmentPath);
      remove(index);
    } catch (err) {
      dispatchError(err);
    }
  };

  const submitItem = async (values, actions, arrayHelpers) => {
    const { setSubmitting } = actions;
    const { push } = arrayHelpers;

    try {
      if (choosenFile) {
        const uploadedFile = await uploadFile(
          `${uuidv4()}/${choosenFile.name}`,
          choosenFile
        );

        values.attachmentPath = uploadedFile.path;
        values.attachmentName = uploadedFile.name;
        values.attachmentURL = uploadedFile.url;
        delete values[itemFileField.name];

        if (
          !fieldArrayValue
            .map((item) => item.attachmentURL)
            .includes(values.attachmentURL)
        ) {
          push(values);
        }
      }
      setChoosenFile(null);
      inputFile.current.value = "";
      setItemFormValues(itemInitialValues);
      setSubmitting(false);
    } catch (err) {
      dispatchError(err);
      setSubmitting(false);
    }
  };

  return (
    <>
      <FieldArray name={field.name}>
        {(arrayHelpers) => {
          return (
            /* The Formik below is the item form for the array field */
            <Formik
              enableReinitialize
              initialValues={itemFormValues}
              validationSchema={itemValidation}
              onSubmit={(values, actions) =>
                submitItem(values, actions, arrayHelpers)
              }
            >
              {(formik) => {
                const disabledField =
                  disabled ||
                  (!!fieldArrayValue && fieldArrayValue.length >= max);
                const disabledAdd =
                  disabled ||
                  (!!fieldArrayValue && fieldArrayValue.length >= max) ||
                  formik.isSubmitting;
                return (
                  <MDBox p={1} bgColor="light" borderRadius="lg">
                    {showTitle && (
                      <MDBox
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                        mt={-2}
                        mx={2}
                        mb={2}
                        textAlign="center"
                      >
                        <MDTypography
                          variant="body3"
                          color="white"
                          fontWeight="medium"
                        >
                          {field.label}
                        </MDTypography>
                      </MDBox>
                    )}
                    <MDBox p={1} mb={1} bgColor="white" borderRadius="lg">
                      {/* not using <Form> because cannot have form within another form (Customer.js),
                      in order not to trigger validation of sub formik when submitting main formik */}
                      <MDBox id={itemFormId} autoComplete="off">
                        <MDBox
                          bgColor="white"
                          borderRadius="lg"
                          display="flex"
                          justifyContent="space-between"
                          alignItems="baseline"
                          flexWrap="nowrap"
                        >
                          <MDBox flexBasis="100%">
                            <TextField
                              inputRef={inputFile}
                              size="small"
                              type={itemFileField.type}
                              disabled={disabledField}
                              fullWidth
                              onChange={(e) => {
                                const file = e.currentTarget.files[0];
                                if (file) {
                                  formik.setFieldValue(
                                    itemFileField.name,
                                    file
                                  );
                                  if (file.size < 5 * 1024 * 1024) {
                                    setChoosenFile(file);
                                  }
                                }
                              }}
                            />
                            <MDBox mt={0.75}>
                              <MDTypography
                                component="div"
                                variant="caption"
                                color="error"
                                fontWeight="regular"
                                sx={{ whiteSpace: "pre-wrap" }}
                              >
                                <ErrorMessage
                                  name={itemFileField.name}
                                ></ErrorMessage>
                              </MDTypography>
                            </MDBox>
                          </MDBox>
                          {!disabledAdd && (
                            <MDBox ml={2}>
                              <MDButton
                                size="small"
                                onClick={formik.handleSubmit}
                                color="success"
                                iconOnly
                              >
                                <AddIcon />
                              </MDButton>
                            </MDBox>
                          )}
                        </MDBox>
                      </MDBox>
                    </MDBox>
                    <MDBox bgColor="white" borderRadius="lg">
                      <List dense>
                        <TransitionGroup>
                          {!!fieldArrayValue &&
                            fieldArrayValue.map((item, index) => (
                              <Collapse key={item[itemKeyField.name]}>
                                {renderItem(item, index, arrayHelpers.remove)}
                              </Collapse>
                            ))}
                        </TransitionGroup>
                      </List>
                    </MDBox>
                  </MDBox>
                );
              }}
            </Formik>
          );
        }}
      </FieldArray>
      <MDBox mt={0.75}>
        <MDTypography
          component="div"
          variant="caption"
          color="error"
          fontWeight="regular"
          sx={{ whiteSpace: "pre-wrap" }}
        >
          <ErrorMessage name={field.name}></ErrorMessage>
        </MDTypography>
      </MDBox>
    </>
  );
}

// Setting default values for the props of FormInnerFieldArray
FormAttachments.defaultProps = {
  removable: true,
  showTitle: true,
};

// typechecking props for FormAttachments
FormAttachments.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  max: PropTypes.number,
  disabled: PropTypes.bool,
  dispatchError: PropTypes.func,
  itemFormId: PropTypes.string,
  itemInitialValues: PropTypes.object,
  itemValidation: PropTypes.object,
  itemFileField: PropTypes.object,
  itemKeyField: PropTypes.object,
  itemDisplayFields: PropTypes.array,
  removable: PropTypes.bool,
  showTitle: PropTypes.bool,
};

export default FormAttachments;
