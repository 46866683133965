import * as Yup from "yup";
import form, {
  rolesForm,
  signatureAttachmentsForm,
} from "pages/account/manage/schemas/form";

const {
  formField: {
    displayName,
    designation,
    email,
    roles,
    emailSignOff,
    signatureAttachments,
  },
} = form;

const noValidation = Yup.object().shape({});

const validations = Yup.object().shape({
  [displayName.name]: Yup.string()
    .required(displayName.errorMsg)
    .max(200, displayName.invalidMsg),
  [designation.name]: Yup.string()
    .required(designation.errorMsg)
    .max(200, designation.invalidMsg),
  [email.name]: Yup.string()
    .required(email.errorMsg)
    .max(200, email.invalidMsg)
    .email(email.invalidMsg),
  [roles.name]: Yup.array()
    // .min(1, serviceTeams.min1Msg)
    .max(20, roles.max20Msg),
  [emailSignOff.name]: Yup.string()
    .required(emailSignOff.errorMsg)
    .max(5000, emailSignOff.invalidMsg),
  [signatureAttachments.name]: Yup.array().max(
    20,
    signatureAttachments.max20Msg
  ),
});

const {
  formField: { roleName },
} = rolesForm;

const rolesValidation = Yup.object().shape({
  [roleName.name]: Yup.string()
    .required(roleName.errorMsg)
    .max(200, roleName.invalidMsg)
    .nullable(true),
});

const {
  formField: { signatureAttachmentFile },
} = signatureAttachmentsForm;

const signatureAttachmentsValidation = Yup.object().shape({
  [signatureAttachmentFile.name]: Yup.mixed()
    .nullable()
    .required(signatureAttachmentFile.errorMsg)
    .test(
      "fileSize",
      signatureAttachmentFile.invalidMsg,
      (value) => !value || (value && value.size <= 5 * 1024 * 1024)
    ),
});

export {
  validations as default,
  noValidation,
  rolesValidation,
  signatureAttachmentsValidation,
};
