import { auth, functions } from "configs/firebase";
import {
  createUserWithEmailAndPassword,
  updateProfile,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signInWithPopup,
  setPersistence,
  sendPasswordResetEmail,
  browserLocalPersistence,
  browserSessionPersistence,
  inMemoryPersistence,
  GoogleAuthProvider,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import { httpsCallable } from "firebase/functions";

const persistence = {
  local: browserLocalPersistence,
  session: browserSessionPersistence,
  none: inMemoryPersistence,
};

const createAuthnUser = (email, password) => {
  return createUserWithEmailAndPassword(auth, email, password);
};

const createNewUser = httpsCallable(functions, "createAuthUser");

const updateUserProfile = (userDisplayName, userPhotoURL) => {
  return updateProfile(auth.currentUser, {
    ...(userDisplayName && { displayName: userDisplayName }),
    ...(userPhotoURL && { photoURL: userPhotoURL }),
  });
};

const sendUserEmailVerification = (continueURL, newUser = auth.currentUser) => {
  return sendEmailVerification(newUser, { url: continueURL });
};

const setRolesOfAuthnUser = httpsCallable(functions, "setCustomClaimsRoles");

const setRolesOfUser = httpsCallable(functions, "setCustomClaimsRolesOfUser");

const getUserRoles = async () => {
  let roles = null;
  const user = auth.currentUser;
  if (user) {
    await user.getIdToken(true);
    const result = await user.getIdTokenResult();
    roles = result?.claims?.roles;
  }
  return roles;
};

const signInUser = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password);
};

const signInUserWithGoogle = () => {
  const provider = new GoogleAuthProvider();
  return signInWithPopup(auth, provider);
};

const setAuthStatePersistence = (persistenceType) => {
  return setPersistence(auth, persistence[persistenceType]);
};

const signOutUser = () => {
  return signOut(auth);
};

const sendUserPasswordResetEmail = (email, continueURL) => {
  return sendPasswordResetEmail(auth, email, { url: continueURL });
};

const subscribeAuthState = (handleAuthStateChanged) => {
  return onAuthStateChanged(auth, (user) => {
    handleAuthStateChanged(user);
  });
};

const getAuthUser = httpsCallable(functions, "getAuthUser");
const disableAuthUser = httpsCallable(functions, "disableAuthUser");
const enableAuthUser = httpsCallable(functions, "enableAuthUser");

export {
  createAuthnUser,
  createNewUser,
  sendUserEmailVerification,
  updateUserProfile,
  setRolesOfAuthnUser,
  setRolesOfUser,
  getUserRoles,
  signInUser,
  signInUserWithGoogle,
  setAuthStatePersistence,
  sendUserPasswordResetEmail,
  signOutUser,
  subscribeAuthState,
  getAuthUser,
  disableAuthUser,
  enableAuthUser,
};
