import PropTypes from "prop-types";

import GenericTemplate from "components/templates/GenericTemplate";

import BackgroundDesign from "../../designs/BackgroundDesign";
import CenterFragment from "../../fragments/CenterFragment";
import FooterFragment from "../../fragments/FooterFragment";

function PlainFullLayout({ image, children }) {
  return (
    <GenericTemplate>
      <BackgroundDesign image={image}>
        <CenterFragment content={children}>
          <FooterFragment color={"white"}></FooterFragment>
        </CenterFragment>
      </BackgroundDesign>
    </GenericTemplate>
  );
}

// Typechecking props for the NavFullLayout
PlainFullLayout.propTypes = {
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default PlainFullLayout;
