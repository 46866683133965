import { useEffect, useState } from "react";

import {
  createAuthnUser,
  updateUserProfile,
  sendUserEmailVerification,
  setRolesOfAuthnUser,
  getUserRoles,
} from "services/authentication";
import { useAuthContext } from "context/AuthContext";
import { useDocument } from "hooks/useDocument";

export const useSignup = () => {
  const [isUnmounted, setIsUnmounted] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const { dispatch } = useAuthContext();
  const { createDoc } = useDocument();

  const message =
    "A link to activate your account has been emailed to the address provided. Please refer to the email to complete the sign up process.";

  const signup = async (
    email,
    password,
    displayName,
    photoURL,
    continueURL
  ) => {
    try {
      setError(null);

      const userCredential = await createAuthnUser(email, password);

      if (!userCredential) {
        throw new Error("Could not complete signup");
      }

      await updateUserProfile(displayName, photoURL);
      await sendUserEmailVerification(continueURL);
      await setRolesOfAuthnUser({ roles: ["MEMBER"] });

      const user = userCredential.user;

      await createDoc(
        "users",
        {
          email: email,
          designation: "",
          displayName: displayName,
          photoURL: photoURL,
          roles: ["MEMBER"],
          root: false,
          system: false,
          signatureAttachments: [],
        },
        user.uid,
        user.uid
      );

      const roles = await getUserRoles();
      dispatch({
        type: "SIGNIN",
        payload: { user: user, roles: roles },
      });

      if (!isUnmounted) {
        setError(null);
        setSuccess(message);
      }
    } catch (err) {
      if (!isUnmounted) {
        // SEE https://cheatsheetseries.owasp.org/cheatsheets/Authentication_Cheat_Sheet.html#authentication-and-error-messages
        // Don't show error on console
        // Respond with generic error message regardless
        // console.error(err.message);
        setError(null);
        setSuccess(message); // using success message as generic error message regardless of failure signup
      }
    }
  };

  useEffect(() => {
    return () => setIsUnmounted(true);
  }, []);

  return { signup, error, success };
};
